.page-footer {

    position: relative;
    padding-top: 8rem;

    &.white {
        padding-top: 0rem;

        @include respond(m) {
            background: $white-color;
            padding-top: 8rem;

        }
    }

    &.grey {

        background: $section-bg;

    }

    &.small {
        padding-top: 5.1rem;

    }
}

.footer {

    .app {
        background: $color-first;

        padding: 1.7rem 2.5rem;
        border-radius: 20px;
        margin-bottom: 2.5rem;

        @include respond(m) {
            padding: 2.7rem 2.5rem;

            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        &__text {
            color: $white-color;
            flex: 1;
            margin-left: 1.5rem;

            h2 {
                line-height: 1.5;
                margin-bottom: 1rem;
                font-weight: 300;
                font-size: 2rem;
                color: $color-typo-main;

                @include respond(m) {
                    font-size: 2.6rem;
                    color: $white-color;

                }

                font-family: 'SF-Medium';

            }

            p {
                line-height: 24px;
                font-size: 1.6rem;
                color: $color-typo-second;
                margin-bottom: 1rem;

                @include respond(m) {
                    font-size: 2rem;
                    color: $white-color;
                    margin-bottom: 0;


                }

            }
        }

        &__btns {
            display: flex;
            align-items: center;
            justify-content: space-between;

            @include respond(s) {
                justify-content: flex-start;

            }

            @include respond(m) {
                justify-content: space-between;

            }

            a:not(:last-child) {
                margin-left: 1rem;
            }

            &.start {
                justify-content: center;

                @include respond(m) {
                    justify-content: flex-start;

                }

            }
        }

    }

    .info {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-wrap: wrap;
        padding: 2.2rem 0;
        color: $white-color;
        font-weight: 300;

        @include respond(m) {
            justify-content: space-between;

        }

        h6 {
            font-weight: 300;
            font-size: $default-font-size;
            margin-bottom: 2.2rem;
            font-size: 1.6rem;

            @include respond(m) {
                font-size: 2rem;

            }

        }

        p {
            font-weight: 100;
            font-size: 1.2rem;

            @include respond(m) {
                font-size: 1.4rem;

            }

        }

        .right {
            text-align: center;
            flex: 1;

            @include respond(m) {
                text-align: right;

            }
        }

        .left {
            text-align: center;
            width: 100%;
            margin-top: 1.7rem;

            @include respond(m) {
                text-align: right;
                width: auto;
                margin-top: 0;

            }

            ul {
                display: flex;
                align-items: center;
                justify-content: center;

                @include respond(m) {
                    justify-content: flex-start;
                }

                li:not(:last-child) {
                    margin: 0 0 0 1rem;
                }
            }
        }
    }

    &.small {
        background: $white-color;
        padding: 2rem 0;

        p {
            font-size: 1.6rem;
            line-height: 23px;

            @include respond(xxxs) {
                font-size: 1.8rem;

            }
        }

        a {
            font-size: 1.6rem;
            line-height: 23px;
            margin-right: .4rem;

            @include respond(xxxs) {
                font-size: 1.8rem;

            }
        }
    }

}


.english {
    .footer .app__btns a:not(:last-child) {
        margin-left: 0;
        margin-right: 1rem;
    }

    .footer .info .right {
        @include respond(m) {
            text-align: left;

        }
    }

    .footer .app__text {
        margin-left: 0;
        margin-right: 1.5rem;
    }

    .footer .info .left ul li:not(:last-child) {
        margin: 0 1rem 0 0;

    }
}