.about {

    // @include respond(m){
    // padding-top: 2.6rem;

    // }
    &__info {
        width: 100%;
        position: relative;
        margin-top: -11.1rem;
        padding: 26.7rem 1.2rem 2rem;
        background: $white-color;
        box-shadow: $box-shadow-select;
        border: 1px solid$color-input;
        border-radius: $border-radius-md;
        z-index: 2;

        @include respond(m) {
            margin-top: 0;

            width: 70%;
            padding: 2.6rem 0 0;
            top: 0;
            background: transparent;
            box-shadow: none;
            border: none;
        }

        h2 {
            font-size: 2rem;
            font-family: 'SF-Medium';
            line-height: 24px;

            margin-bottom: 1.5rem;

            @include respond(m) {
                font-size: 2.6rem;
                line-height: 31px;

            }
        }

        p {
            font-size: 1.6rem;
            line-height: 30px;
            color: $color-typo-second;

            &:not(:last-child) {
                margin-bottom: .8rem;
            }

            @include respond(m) {

                font-size: 2rem;

            }
        }
    }

    &__services {
        padding-top: 3rem;

        @include respond(m) {
            padding-top: 8.1rem;


        }

        h1 {
            font-size: 2.6rem;
            font-family: 'SF-Medium';
            line-height: 31px;
            margin-bottom: 3rem;
            text-align: center;
        }

        &--boxes {
            // margin-bottom: 2rem;
            display: flex;
            align-items: stretch;
            justify-content: space-between;
            flex-wrap: wrap;

            @include respond(m) {
                margin-bottom: 2rem;


            }

            &_box {
                margin-bottom: 1.4rem;

                @include respond(m) {
                    margin-bottom: 0;


                }

                &:not(:last-child) {
                    @include respond(m) {
                        margin-left: 2rem;

                    }
                }

                width: 100%;
                display: flex;
                flex-direction: column;

                @include respond(m) {
                    width: 49%;
                }

                background: #fff;
                border-radius: $border-radius-md;
                padding: 2rem 1.6rem 2rem 2.4rem;

                img {
                    border-radius: 4px;
                    width: 6.3rem;
                    height: 5.1rem;
                }

                h5 {
                    font-size: 1.6rem;

                    font-family: 'SF-Medium';
                    line-height: 19px;
                    margin: 1.2rem 0 1.1rem;
                    color: $color-first;
                }

                p {
                    font-size: 1.4rem;
                    color: $color-typo-second;

                    line-height: 24px;
                }
            }
        }
    }

    &__detail {
        padding-top: 3rem;

        @include respond(m) {
            padding-top: 2rem;


        }

        &--title {
            color: $color-typo-main;
            font-family: "SF-Medium";
            font-weight: 100;
            font-size: 2.6rem;
            margin-bottom: 2rem;

            @include respond(m) {
                margin-bottom: 3rem;

            }

            text-align: center;
        }

        &--boxes {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
        }

        &--box {
            display: flex;
            width: 100%;
            background: $white-color;
            border-radius: $border-radius-md;
            padding: 2rem 2rem 2.1rem;

            &:not(:last-child) {
                margin-bottom: 1.4rem;

                @include respond(m) {
                    margin-bottom: 0rem;


                }
            }

            @include respond(m) {
                width: 31%;

            }

            .icon {
                background: rgba($color: $color-first, $alpha: .1);
                display: flex;
                margin-left: 1.4rem;
                align-items: center;
                justify-content: center;
                width: 3.9rem;
                padding: .8rem;
                height: 3.9rem;
                border-radius: $border-radius;

                img {
                    width: 1.9rem;

                }
            }

            .content {
                h1 {
                    font-family: "SF-Medium";
                    font-weight: 100;
                    font-size: 2rem;
                    margin-bottom: .6rem;

                }
            }
        }
    }
}

.english {
    .about {

        &__services {

            h1 {

                text-align: center;
            }

            &--boxes {

                &_box {
                    &:not(:last-child) {
                        @include respond(m) {
                            margin-right: 2rem;
                            margin-left: 0;

                        }
                    }


                    padding: 2rem 2.4rem 2rem 1.6rem;

                    img {}

                    h5 {}

                    p {}
                }
            }
        }
    }

    .about__detail--box .icon {
        margin-left: 0;
        margin-right: 1.4rem;

    }
}