.hero {
    position: relative;
    z-index: 2;
    min-height: 270px;

    @include respond(m) {
        min-height: 360px;

    }

    &__title {
        display: inline-block;
        position: absolute;
        top: 45%;
        left: 50%;
        white-space: nowrap;
        transform: translate(-50%, -50%);
        font-family: 'SF-Bold';
        color: #fff;

        @include respond(xs) {


            // font-size: 3.6rem;

        }

        @include respond(m) {
            top: 50%;


        }

        h1 {
            font-size: 2.6rem;
            margin-bottom: 1.6rem;

            @include respond(s) {
                font-size: 3.6rem;

            }

            @include respond(m) {
                font-size: 4rem;

            }

            @include respond(l) {
                font-size: 5rem;

            }
        }

    }
}

.big-hero {

    background: $color-first;
    // min-height: 82vh;
    padding-top: 9.2rem;
    padding-bottom: 5.7rem;
    position: relative;

    @include respond(m) {
        padding-top: 12.4rem;

    }

    &__content {
        display: flex;
        justify-content: space-between;
        align-content: center;
        flex-wrap: wrap;

    }

    &__left {
        flex: 100%;
        min-width: 28.9rem;
        max-width: 50.6rem;
        margin: 0 auto;

        @include respond(xxxs) {

            min-width: 33.9rem;

        }

        @include respond(s) {
            flex: 1 1;
            margin: 0 0;

        }
    }

    &__right {
        margin: 0 auto;

        @include respond(s) {
            margin: 0 0;

        }

        h1 {
            font-family: 'SF-Medium';
            font-size: 1.8rem;
            color: $color-typo-main;
            line-height: 5.1rem;
            text-align: center;

            span {
                background: $white-color;
                padding: .2rem .4rem .5rem;
            }

            @include respond(xxxs) {
                font-size: 2rem;

            }

            @include respond(m) {
                text-align: initial;
                font-size: 3rem;

            }
        }

        p {
            color: $color-typo-second;
            margin: 2rem 0 .8rem;
            font-size: 2rem;
            text-align: center;

            @include respond(m) {
                font-size: 2.4rem;
                text-align: initial;
            }
        }

        h6 {
            color: $white-color;
            font-size: 2rem;
            margin-bottom: 3.7rem;
            text-align: center;

            @include respond(m) {
                font-size: 2.4rem;
                flex-wrap: wrap;
                text-align: initial;
            }
        }

        .app__btns {
            margin-bottom: 3.7rem;

            @include respond(m) {
                margin-bottom: 0;

            }
        }
    }
}