.home-about {
    padding: 8rem 0;
    // display: flex;
    // justify-content: space-between;
    // align-items: center;
    background: $white-color;
@include respond(xs){
    padding: 4rem 0;

}
.wrapper{
    flex-wrap: wrap;
    flex-direction: column-reverse;
    @include respond(xs){
        flex-direction: row;

            
        }
}
    img {
        width: 100%;
        margin-left: 0rem;
        @include respond(xs){
            width: 35%;
        margin-left: 3rem;
            
        }
        @include respond(m){
          
        margin-left: 7.9rem;
            
        }
    }

    &__content {
        flex: 1;
        text-align: center;
        @include respond(m){
            text-align: initial;
        }
        h2 {
            font-family: 'SF-Medium';
            font-size: 3rem;
            line-height: 3.6rem;
            margin-bottom: 2.8rem;
         

        }

        p {
            font-size: 2rem;
            line-height: 3rem;
            text-align: initial;
            color: $color-typo-second;
            @include respond(m){
                font-size: 2.2rem;

                }
        }
    }
}

.home-slider {
    background: $color-first;
    padding: 3.7rem 0 3.7rem;

    @include respond(m) {
        padding: 3.7rem 0 3rem;

    }
    a{
        text-decoration: none;
    }
}

.home-why {
    padding: 4rem 0;
    background: $section-bg;
    .wrapper{
        flex-wrap: wrap;

    }
    &__content {
        text-align: center;
        @include respond(m){
        text-align: initial;
            
        }

        h1 {
            font-family: 'SF-Medium';
            font-size: 3rem;
            line-height: 3.6rem;
            text-align: center;
            @include respond(m){
            text-align: initial;
                
            }

        }

        .sub {
            margin: 3.2rem 0 2.7rem;
            font-size: 2.2rem;
            line-height: 3rem;
            color: $color-typo-second;
            text-align: center;
            @include respond(m){
            text-align: initial;
                
            }
        }
    }

    &__list {
        text-align: initial;
        margin-bottom: 6.8rem;
        @include respond(m){
            margin-bottom: 0rem;

            }
        &--item {
            margin-bottom: 2rem;
            display: flex;
            align-items: flex-start;

            .img {
                width: 4.2rem;
                height: 4.2rem;
                padding: 1rem 1.2rem 1.1rem;
                border-radius: $border-radius;
                background: $color-first;

            }

            .text {
                margin: 0 1rem;

                h2 {
                    color: $color-first;
                    line-height: 1.9rem;
                }

                p {
                    font-size: 1.4rem;
                    line-height: 2.4rem;
                    color: $color-typo-second;

                    @include respond(s) {
                        width: 90%;
                    }
                    @include respond(m) {
                        width: 70%;
                    }
                }
            }
        }
    }

    &__img {
        flex: 100%;
        min-width: 28.5rem;
        max-width: 50.9rem;
      margin: 0 auto;

      @include respond(m){
        min-width: 32.5rem;


      }
      
      @include respond(m){
        flex: 1 1;
  margin: 0 0;

      }
    }
}


.home-numbers {
    padding: 4rem 0;
    background: $white-color;
    text-align: center;

    h1 {
        font-family: 'SF-Medium';
        font-size: 3rem;
        line-height: 3.6rem;
        margin-bottom: 5rem;
        text-align: center;
    }

    &__list {
        display: flex;
        align-items: center;
        justify-content: space-around;
        text-align: center;
        flex-wrap: wrap;

        &--item {
            margin: 1.5rem 2rem;
            width: 100%;
            @include respond(xs){
                width: 48%;
            
    
                }
                @include respond(s-x){
                    width: auto;
                
        
                    }
            @include respond(m){
            margin: 0 2rem;

            }
            .img {
                width: 11.2rem;
                height: 9.7rem;
                margin: 0 auto 1.8rem;

                img {
                    height: 100%;
                }
            }

            .text {
                h6 {
                    font-family: 'SF-Medium';
                    font-size: 2rem;
                    margin-top: .4rem;
                    color: $color-first;
                }
            }
        }
    }
}


.testmonials {
    padding: 4rem 0;
    background: $color-first;
    text-align: center;

    &__content {
        max-width: 84.9rem;
        margin: 0 auto;

        .slick-list {
            padding-bottom: 1.8rem;
        }

        .slick-dots {
            position: relative;
            bottom: 0;
        }
    }

    h1 {
        font-family: 'SF-Medium';
        font-size: 3rem;
        line-height: 3.6rem;
    }

}

.testmonial {
    direction: rtl;

    .text {
        position: relative;
        margin: 5rem 0;
@include respond(s){
    margin: 5rem 2.27rem;

}
        .quote {
            &-top {
                position: relative;
                top: 0%;
                right: 0;
                opacity: .5;
                width: 3.94rem;
                height: 2.429rem;
            }

            &-bottom {
                position: absolute;

                left: 0;
                opacity: .5;
                width: 3.94rem;
                height: 2.429rem;

            }

        }
    }

    p {
        font-size: 2rem;
        line-height: 4rem;

        color: $white-color;
        padding: 1.6rem 0;
    }

    h2 {
        font-size: 2rem;
        font-family: 'SF-Medium';
        margin-bottom: .8rem;
    }

    h4 {
        color: $color-typo-second;
        font-size: 1.7rem;
    }
}


.clients {
    padding: 4rem 0;
    background: $white-color;
    text-align: center;

    h1 {
        font-family: 'SF-Medium';
        font-size: 3rem;
        line-height: 3.6rem;
        margin-bottom: 5.3rem;
    }
    .sponsor{
        width:5.3rem;
        height: 6.3rem;
        @include respond(m){
            width:7.6rem;
            height: 8.4rem;
        }
    }

}

.home-info {
    padding-top: 5.6rem;
    background: $section-bg;

    .wrapper{
       text-align: center;
    }
    .about__detail{
        padding-top: 0;
    }
}

.english {
    .home-about img {
        margin-left: 0;
      
        margin-right:0rem;
        @include respond(xs){
        
        margin-right: 3rem;
            
        }
        @include respond(m){
          
        margin-right: 7.9rem;
            
        }
    }

    .home-numbers__list--item {
        margin: 1.5rem 0rem;
        @include respond(m){
                  margin: 0 2rem;

                
            }
        .img {}
    }

    .testmonial {
        direction: ltr;

        .text {
            .quote {
                &-top {

                    transform: rotate(180deg);
                }

                &-bottom {
                    right: 0;
                    left: auto;
                    transform: rotate(180deg);
                }
            }
        }
    }
}