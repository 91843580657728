.contact {
    position: relative;
    margin-top: -11.1rem;
    z-index: 3;
  
    @include respond(m){
     
    margin-top: 0;
    padding-top: 6rem;

    }
    &__header{
    //     position: absolute;
       
    //     left:0;
    //     right: 0;
    //     top: -10rem;
 
    // z-index: 2;
        &--content{
            background: $white-color;
            border-radius: $border-radius;
            box-shadow: $box-shadow-select;
            padding: 1.6rem;
            text-align: center;
            @include respond(m){
                background: none;
                border-radius: 0;
                box-shadow: none;
                padding: 0;
                text-align: right;
            }
        }
        @include respond(m){
           // position: relative;
           // top: 0;
        }
        }
    &__info {
        margin: 2rem 0 .8rem;
        @include respond(m){
            margin: 5rem 0;

        }
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        justify-content: center;
    }

    &__form {
      
        box-shadow: $box-shadow-select;
        border-radius: $border-radius;
        background: $white-color;
        padding: 2rem 1.5rem;
        flex: 1;
        .btn{
            width: 100%;
        }
        @include respond(m){
            margin-left: 4rem;

        }
    }

    &__detail {
        width: 100%;
        background: $white-color;
        border-radius: $border-radius;
        box-shadow: $box-shadow-select;
        padding: 1.6rem;
     margin-top: 2rem;
        @include respond(m){
            width: 40%;
            background: none;
            border-radius: 0;
            box-shadow: none;
            padding: 0;
            margin-top: 0;
          
        }
        &--title {
            color: $color-first;
            font-family: "SF-Medium";
            font-weight: 100;
            font-size: 2.6rem;
    margin-bottom: 2.6rem;

        }

        &--box {
            display: flex;
            margin-bottom: 2.6rem;
            @include respond(m){
            margin-bottom: 3rem;

            }

            .icon {
                background: $color-first;
                display: flex;
                margin-left: 1rem;
                align-items: center;
                justify-content: center;
                width: 3.9rem;
                padding: .8rem;
                height: 3.9rem;
                border-radius: $border-radius;

                img {
                    width: 1.9rem;

                }
            }

            .content {
                h1 {
                    font-family: "SF-Medium";
                    font-weight: 100;
                    font-size: 1.8rem;
                    margin-bottom: .7rem;
                    @include respond(m){
                    margin-bottom: .9rem;
                    font-size: 2rem;

                    }
                }
            }
        }
    }
}

.english{
    .contact__header--content{
        @include respond(m){
            text-align: left;
        }
    }
    .contact__form{
        @include respond(m){
            margin-right: 4rem;
            margin-left: 0;

        }   
    }
    .contact__detail--box .icon{
        margin-left:0;
        margin-right:1rem;

    }
}