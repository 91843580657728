.services{
    position: relative;
    margin-top:-9.6rem;
    z-index: 3;
    margin-bottom: 3.5rem;
    display: inline-block;
    width: 100%;
    // @include respond(h){
    // margin-top:-12.6rem;

    // }
    @include respond(m){
        position: relative;
   // top: 0;
    margin-bottom: 0;
    display: block;
    margin-top: 0;

    
    }
    &__box{
        padding:0;
        display:flex;
        align-items: center;
        justify-content: center;
      position: relative;
      z-index: 1;
      flex-direction: column;
      align-items: flex-start;


      background: $white-color;
      padding: 1.4rem;
      box-shadow: $box-shadow-select;
      
      border-radius: $border-radius;
      
      margin: 0 auto;
      @include respond(m){
          background: transparent;
          padding: 0;
          box-shadow: none;
     
      border-radius: 0;

      }
     
        @include respond(m){
padding: 9rem 8rem;
flex-direction: row;
align-items: center;

        }
        &--content{
            flex: 1;
            padding-right: 0;

            @include respond(m){
                padding-right: 8rem;

                        }
            h1{
            font-family: 'SF-Medium';
                font-size: 2rem;
                @include respond(m){
                font-size: 2.6rem;

                }
               
            }
            p{
                line-height: 30px;
                color:$color-typo-second;
                margin: 2rem 0;
                
                width: 100%;
                @include respond(m){
                    font-size: 2rem;
    
                    }
               
            }
        }
        &--image{
            width: 40%;
            max-width: 200px;
            text-align: left;
            margin-bottom: 1.5rem;
            @include respond(m){
               margin: 0;
            width: 20%;


                        }
 
        }
      
    }
    &__wrapper{
        position: relative;
       margin: 0 auto 3rem auto;
       @include respond(m){
        margin:0   
       }
        &.white{
            &::before{
                content: '';
                background: $white-color;
        
                display: block;
                position: absolute;
                width: 100%;
                height: 40px;
                z-index: 0;
                top: -17px;
                @include respond(l){
                    height: 50px;
                    top: -25px;

                }
                @include respond(xxl){
                    height: 70px;
                    top: -35px;

                }
                
                transform: rotate(
            -1.5deg
            );
            }
            &::after{
                content: '';
                background: $white-color;
        
                display: block;
                position: absolute;
                width: 100%;
                height: 40px;
                right: 0;
                left: 0;
                z-index: 0;
                bottom: -17px;
                @include respond(l){
                    height: 50px;
                    bottom: -25px;

                }
                @include respond(xxl){
                    height: 70px;
                    bottom: -35px;

                }
                transform: rotate(
            -1.5deg
            );
            }
          
            background: transparent;
            @include respond(m){
            background: $white-color;
               
    
            }
           
            &::before ,&::after{
                display: none;
                @include respond(m){
                    display: block;
                }
            }
               
        }
        &.white{
            .services__box{
                flex-direction: column-reverse;
            @include respond(m){
                padding: 6rem 8rem;
                flex-direction: row;

                        }
                &--content{
                    padding-right: 0rem;
                    p{
                        width: 100%;
                        font-size: 1.6rem;
                        @include respond(m){
                            width: 85%;
                            font-size: 2rem;

                        }
                    }
                }
            }
    
          }
    }
}

.english{
    .services__box--content{
        padding-right: 0;
        padding-left:0;
        @include respond(m){
            padding-right: 0;
            padding-left: 8rem;
        }
       
    }
    .services__wrapper.white{
        .services__box--content{
        padding-left: 0;
        }
    }
}