.page-title {
    font-size: 2rem;
    font-family: 'SF-Medium';
    margin: 1.1rem 0;
    line-height: 30px;
    font-weight: 100;

    @include respond(s) {
        font-size: 2.5rem;

    }

    &.small {
        margin: 0;

        line-height: 30px;
        // margin: .9rem 0;

        font-weight: 100;
        font-size: 1.8rem;

        @include respond(m) {
            line-height: 40px;
            font-size: 2rem;

        }

    }

    a {
        margin: 0 .5rem;
    }
}

.page-sub {
    font-size: $default-font-size;
    font-weight: 300;
    margin: 1rem 0;
    color: $color-typo-third;
    font-family: 'SF-Regular';

    @include respond(s) {
        font-size: 2rem;

    }

    @include respond(m) {
        margin: 1.9rem 0;

    }

}


.box-title {
    color: $color-first;
    font-family: "SF-Medium";
    font-weight: 100;
    font-size: 2.6rem;
    margin-bottom: 2.6rem;

}