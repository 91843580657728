.nav {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 1000;
    min-width: 100%;
    transition: all .4s;

    a {

        text-decoration: none;
        color: $white-color;

        font-family: 'SF-Medium';
    }

    .header {

        display: flex;
        justify-content: space-between;
        align-items: center;
        transition: all .3s;
        padding: 3rem 0 3rem 0;

        @include respond(m) {
            padding: 2rem 0 2.2rem 0;

        }

        font-family: 'SF-Medium';

        & .logo,
        & .menu {
            position: relative;

            color: $white-color;
            text-emphasis: none;
            font-size: 2.2rem;
            text-decoration: none;
            transition: .3s;

            //width: 10%;
            & img {
                width: 100%;

                @include respond(m) {
                    width: 100%;

                }

            }
        }

        & .logo {
            text-align: left;

            @include respond(m) {
                text-align: right;

            }
        }

        .menu {
            display: flex;

            @include respond(m) {
                display: none;
            }
        }


        & ul {
            position: relative;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            flex: 1;
            display: none;
            visibility: hidden;

            @include respond(m) {
                display: flex;
                visibility: visible;
            }

            & li {
                position: relative;
                list-style: none;
                cursor: pointer;

                & a {
                    position: relative;



                    transition: all .3s;

                    // &:hover {
                    //     color: $color-second;
                    // }
                }

                .title-line {
                    opacity: 0;
                    transition: all .3s;
                    position: absolute;
                    left: 50%;
                    transform: translateX(-50%);
                }

                &:not(:last-child) {
                    & a {

                        margin: 0 15px;
                    }
                }

                &:hover {
                    .title-line {
                        opacity: 1;

                    }
                }

                &.active {

                    .title-line {
                        opacity: 1;

                    }
                }
            }

            .btns {
                @include respond(m) {
                    display: none;
                }
            }
        }

    }

    .white-logo {
        display: block;

    }

    .main-logo {
        display: none;

    }

    &.scrolled {
        background-color: $color-first;
        box-shadow: $box-shadow-select;

        @include respond(m) {
            background-color: $white-color;

        }

        .white-logo {

            @include respond(m) {
                display: none;
            }
        }

        .main-logo {
            display: none;

            @include respond(m) {
                display: block;
            }
        }

        .header {
            padding: 2rem 0 1.8rem 0;


            & .logo,
            .menu {
                opacity: 1;
                color: $color-typo-main;
            }

            ul li .title-line div {

                background: rgba($color: $color-first, $alpha: 1)
            }

            ul li a {
                color: $color-typo-second;

            }

            ul li.active {
                a {
                    color: $color-first
                }
            }

        }

    }

    &.custom-nav {
        background: $color-first;

        @include respond(m) {
            background: $white-color;

            // border: 1px solid #707070;
        }

        .header {
            padding: 2rem 0 1.8rem 0;


        }

        &.scrolled {
            //  box-shadow: none;

        }

        .mobile .header ul {
            flex-direction: column-reverse;
        }

        .white-logo {
            display: block;

            @include respond(m) {
                display: none;
            }
        }

        .main-logo {
            display: none;

            @include respond(m) {
                display: block;
            }
        }
    }




    .mobile {
        opacity: 0;
        visibility: hidden;
        transform: translateY(-500px);
        transition: all .3s;
        position: absolute;
        left: 0;
        right: 0;
        transition-delay: .3s;
        z-index: 2;

        &.show {
            z-index: 2;

            opacity: 1;
            visibility: visible;
            transform: none;
            transition-delay: .1s;


        }

        .header {
            display: flex;
            background: $white-color;
            visibility: visible;
            flex-direction: column;
            border-radius: 0 0 $border-radius $border-radius;
            padding: 2rem 2rem;

            @include respond(xxs2) {
                padding: 2rem 3rem;

            }

            .action-btns {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;

            }

            & .logo,
            & .menu {
                width: auto;

                img {
                    width: 100%;
                }
            }

            ul {
                display: flex;
                flex-direction: column;
                margin-top: 3.5rem;
                opacity: 0;
                visibility: hidden;
                transform: translateX(-20px);
                transition: all .2s;
                transition-delay: .2s;

                li {
                    padding: .9rem 1rem .9rem;

                    a {
                        color: $color-typo-second;
                    }

                    &.active {
                        margin: 0 0 1rem 0;

                        a {
                            color: $color-first
                        }

                        .title-line {
                            opacity: 1;

                            &>div {
                                background: rgba($color: $color-first, $alpha: 1)
                            }
                        }
                    }
                }

                .btns {
                    display: flex;
                    align-items: center;

                    a {
                        margin: 0;

                        &:not(:last-child) {
                            margin: 0 0 0 15px;
                        }
                    }

                }

                li,
                .btns {

                    opacity: 1;
                    visibility: visible;
                    // transform: translateY(-20px);
                    transition: all .2s;
                    // &.show{
                    //     opacity: 1;
                    //     visibility: visible;
                    // transform: none;
                    // transition-delay: .3s;

                    // }
                }

                &.show {
                    opacity: 1;
                    visibility: visible;
                    transform: none;

                    transition-delay: .4s;
                }
            }

            @include respond(m) {
                display: none;
            }
        }
    }

    &.desktop {
        ul {
            display: none;
            visibility: hidden;

            @include respond(m) {
                display: flex;
                visibility: visible;
            }

        }
    }

    &.mobile {
        transition: all .2s;


        &.white {
            background: #fff;

        }

        .header {
            display: flex;
            visibility: visible;
            flex-direction: column;

            .action-btns {
                display: flex;
                align-items: center;
                width: 100%;
                justify-content: space-between;

            }

            // & .logo, & .menu {
            //     width: auto;
            //     img{
            //         height: auto;
            //     }
            // }
            ul {
                flex-direction: column;
                margin-top: 1.5rem;
                opacity: 0;
                visibility: hidden;
                transform: translateX(-20px);
                transition: all .2s;
                transition-delay: .4s;

                li {
                    a {
                        color: $color-typo-second;
                    }

                }

                li {
                    opacity: 0;
                    visibility: hidden;
                    transform: translateY(-20px);
                    transition: all .2s;
                    transition-delay: .6s;

                    &.show {
                        opacity: 1;
                        visibility: visible;
                        transform: none;

                    }
                }

                &.show {
                    opacity: 1;
                    visibility: visible;
                    transform: none;

                }
            }

            @include respond(m) {
                display: none;
            }
        }
    }
}



.english {
    .nav .mobile .header ul .btns a:not(:last-child) {
        margin-left: 0;
        margin-right: 1.5rem;
    }

    .nav .header .menu img {
        transform: rotate(180deg);
    }
}