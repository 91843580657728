.challenges {
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin:0 -1rem;
        &--col {
        text-decoration: none;

            padding: 0 1rem;
            width: 100%;
            // @include respond(xs){
            //     width: 48%;

            //         &:not(:last-child){
            //             margin-left: 2rem;

            //         }
            //     }
            margin-bottom: 2rem;

            @include respond(s) {
                width: 50%;


            }

            @include respond(m) {
                width: 33.3333%;

                margin-bottom: 3rem;

            }

            @include respond(l2) {
                width: 25%;


            }
        }
    }

    &__card {
    direction: rtl ;

        padding: 1.4rem 1.4rem 2rem;
        background: $white-color;
        box-shadow: $box-shadow-select;
        border-radius: $border-radius;
        &--img {
            border-radius: $border-radius;
            background: $color-input;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 5.9rem;
                height: 5.9rem;
                margin-top: -5.5rem;

                @include respond(m){
                   margin-top: 0;
                    }
            }

            margin-bottom: 1.6rem;
            width:100%;
            height: 31.1rem;
            @include respond(m){
            height: 23rem;

            }
            &.big{
                height: 22.1rem;
                @include respond(xxs){
                    height: 26.1rem;

                    }

            }
        }

        &--content {
            h1 {
                font-size: 2rem;
                font-family: 'SF-Medium';
                line-height: 2.6rem;
                margin-bottom: .9rem;
color: $color-typo-main;
@include respond(xxxs){
    font-size: 2.2rem;

}
            }

            .price {
                font-size: 2rem;
                @include respond(xxxs){
                    font-size: 2.2rem;
                
                }
                line-height: 2.6rem;

                color: $color-first;

                
            }
            &.details{
                .price {
                   
    
                    color: $color-typo-second;
                    span{
                        font-size: 2rem;
                    }
    
                    .main{
                        color: $color-first;
                            span{
                                font-size: 1.4rem;
                            }
                        }
                        .text{
                            // margin: 0 .5rem;
                        }
                        .red{
                            color: $color-error;
                            span{
                                font-size: 1.4rem;
                            }
                            }
                }
            }

        }
        
    }
}


.withdraws{
margin-top: 4rem;
    @include respond(m){
margin-top: 2rem;

    }
    &__card{
        padding: .8rem 1rem .9rem .8rem;
        
        background: $white-color;
        box-shadow: $box-shadow-select;
        border-radius: $border-radius-md;

margin-bottom: 2rem;
        .number{
            width: 4.3rem;
            height: 4.3rem;
            border-radius: $border-radius-md;
            background: $color-input;
            font-size: 2.5rem;
            font-family: 'SF-Medium';

           
        }
        .content{
            flex: 1;
            margin: 0 .9rem;
            h1{
                font-family: 'SF-Medium';
                line-height: 19px;
                margin-bottom: .7rem;
                span{
                    color: $color-first;
                    
                }
            }
            p{
                font-size: 1.4rem;
                color: #707070;
            }
        }

        &.titled{
            background: $color-second;
            @include respond(m){
            background: $color-first;

            }
            color: $white-color;
        padding: .9rem 1.8rem .9rem .5rem;
        .number{
            background: $white-color;
            img{
                width: 1.529rem;
                height: 2.371rem;
            }
        }
        .content{
            p{
                color: $white-color;
            }
        }

        }
        &.sub{
            padding-top: .9rem;
            .number{
                color: $color-typo-second;

            }
           .content{
               p{
                color: $color-typo-second;
               }
           }
        }
    }
}


.copons {

    &__row {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        flex-wrap: wrap;
        margin:0 -.45rem;
        @include respond(m){
            margin:0 -1rem;

        }
        &--col {
        text-decoration: none;

            padding: 0 .45rem;
            @include respond(m){
                padding: 0 1rem;

            }
            width: 50%;
            // @include respond(xs){
            //     width: 48%;

            //         &:not(:last-child){
            //             margin-left: 2rem;

            //         }
            //     }
            margin-bottom: 2rem;

            @include respond(s) {
                width: 33.333%;


            }

            @include respond(m) {
                width: 25%;

                margin-bottom: 3rem;

            }

            @include respond(l2) {
                width: 20%;


            }
        }
    }

    &__card {
        padding: 2rem;
        background: $white-color;
        box-shadow: $box-shadow-select;
        border-radius: $border-radius;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        height: 14.9rem;
        @include respond(m){
            height: 20.9rem;

        }
        &.has-alert{
            border: 1px solid $color-first;
        }
        &--img {
           
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 5.7rem;
                height: 5.7rem;
                @include respond(m){
                    width: 8.2rem;
                height: 8.2rem;
                }
            }

            margin-bottom: 1.6rem;
        @include respond(m){
            margin-bottom: 3rem;

        }
        }
        position: relative;
        &--content {
            h1 {
                font-size: 2rem;
                font-family: 'SF-Medium';
                line-height: 2.6rem;
                
                @include respond(m){
                    font-size: 2.2rem;

                }
color: $color-typo-main;
            }
            span{
                background: $color-first;
                font-size: 2rem;
                border-radius: 50%;
                width: 3.4rem;
                height: 3.4rem;
                color:$white-color;
                position: absolute;
                top: 1rem;
                left: 1rem;
            }
           
        }
    }
}

.english{

    .challenges {

        &__card{
            direction: ltr;
        }
    }
}
