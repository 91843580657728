.form {


    &__group:not(:last-child) {
        margin-bottom: 1rem;

        &.btm {
            margin-bottom: 1rem;

        }

        &.sm-btm {
            margin-bottom: 1.2rem;
        }

        &.md-btm {
            margin-bottom: 1.4rem;

        }

        &.md-btm2 {
            margin-bottom: 1.6rem;

        }

        &.big-btm {
            margin-bottom: 1.8rem;

        }

    }

    &__group {

        width: 100%;
    }

    &__input {
        font-size: 1.4rem;
        padding: 1.5rem 1.6rem 1.6rem;
        font-family: inherit;
        color: $color-typo-main;
        border-radius: $border-radius;
        background-color: $color-input;
        border: none;
        border-bottom: 3px solid transparent;
        width: 100%;
        height: 5rem;
        display: block;
        transition: all .3s;

        &--small {
            width: 100%;
            display: inline-block;

        }

        &--textarea {
            min-height: 132px;
        }

        &:focus {
            outline: none;
            box-shadow: 0 .5rem 1rem rgba($color-typo-main, $alpha: .1);
            border-bottom: 3px solid $color-first;

            // &:invalid {
            //     border-bottom: 3px solid $color-first;
            // }
        }

        &.success {
            border-bottom: 2px solid $color-second;

            &:focus {
                border-bottom: 3px solid $color-second;

            }

        }

        &.error {
            border-bottom: 2px solid $color-error;

            &:focus {
                border-bottom: 3px solid $color-error;

            }

        }

        &.circle {
            border: 1px solid transparent;

            transition: all .3s;

            &:focus {
                border-bottom: none;
                // border: 2px solid $color-first;
                border: 1px solid transparent;

                box-shadow: none;
            }

            &.success {

                border: 1px solid $color-second;

            }

            &.error {

                border: 1px solid $color-error;

            }

        }

        &.has-icon {
            padding-right: 4rem;
        }

        &.has-left-icon {
            padding-left: 4rem;

        }

        &.code {
            padding: 1.4rem 1.6rem 1.7rem;
            width: 5rem;
            height: 5rem;
            border: none;
            margin: 0 .6rem;
            text-align: center;
            transition: all .3s;
            font-size: 2.2rem;
            font-family: 'SF-Medium';

            @include respond(xxs) {

                width: 6rem;
                height: 5rem;
            }

            &__success {
                background: $color-first;
                color: $white-color;

            }

            &__error {
                background: $color-error;
                position: relative;

                &::after {}
            }

            &:focus {
                border-bottom: none;

                border: none;

                box-shadow: none;
            }

        }

        &--error-dot {
            content: '';
            position: absolute;
            background: $white-color;
            border-radius: 50%;

            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 1rem;
            height: 1rem;
            ;
        }

        &::placeholder {
            opacity: 1;
            color: $color-typo-third;
            font-size: 1.4rem;
        }

        &::-moz-input-placeholder {
            opacity: 1;

        }

        &::-webkit-input-placeholder {
            color: $color-typo-third;
            font-size: 1.4rem;
        }
    }

    &__icon {
        width: 1.6rem;
        height: 1.6rem;
        position: absolute;
        right: 1.4rem;
        top: 50%;
        transform: translateY(-50%);

        &--left {
            width: 1.6rem;
            height: 1.6rem;
            position: absolute;
            left: 1.4rem;
            top: 50%;
            transform: translateY(-50%);
        }
    }

    &__lines {
        display: flex;
        align-items: center;

        span {
            font-size: 1.4rem;
            line-height: 19px;
            margin: 0 .8rem;
        }

        div {
            flex: 1;
            height: 1px;
            background: $color-input;
        }
    }

    &__label {
        font-size: 1.2rem;
        font-weight: 700;
        margin-right: 2rem;
        margin-top: .7rem;
        display: none; //it was block
        transition: all .3s;

    }

    &__input:placeholder-shown+&__label {
        // when the place holder is shown on the input, do the following
        //the + for choosing sibilings and ~ for all sibilings
        opacity: 0;
        visibility: hidden;
        transform: translateY(-4rem);
    }

    &__radio-group {
        //  width: 100%;
        margin-block-end: .6rem;
        display: inline-block;

        &:not(:last-child) {
            margin-left: 1rem;

            @include respond(m) {
                margin-left: 2rem;

            }
        }

        font-family: 'SF-Medium';
        // @include respond(s){
        //     width: 45%;
        // }

    }

    &__radio-input {
        display: none;
    }

    &__radio-label {
        font-size: $default-font-size;
        cursor: pointer;
        position: relative;
        display: flex;
        align-items: center;
        padding-right: 3rem;
    }

    &__radio-button {
        height: 2rem;
        width: 2rem;
        border-radius: 50%;
        border: 4px solid transparent;
        background: $color-input;
        display: inline-block;
        position: absolute;
        top: 0;
        transition: all .3s;

        right: 0;

        &::after {
            content: "";
            display: block;
            height: .9rem;
            width: .9rem;
            border-radius: 50%;
            position: absolute;
            top: 50%;
            right: 50%;
            transform: translate(50%, -50%);
            background-color: $color-first;
            opacity: 0;
            transition: opacity .3s;
        }

    }

    &__radio-input:checked~&__radio-label &__radio-button {
        border: 4px solid $color-first;
        background: $white-color;
    }

    &__footer {
        img {
            width: 2rem;
        }

        p {
            font-size: 1.4rem;
            color: $color-first;
        }
    }

    //  &__radio-input:checked ~ &__radio-label &__radio-button::after{
    //     opacity: 1;
    //  }
}

input[type=checkbox]+label {
    display: flex;
    align-items: center;

    cursor: pointer;

}

input[type=checkbox] {
    display: none;
}

input[type=checkbox]+label .check {
    content: "\2714";
    border: 0.1rem solid transparent;
    border-radius: $border-radius-sm;
    display: inline-block;
    width: 2rem;
    height: 2rem;
    background: $color-input;
    margin-left: .6rem;
    // vertical-align: bottom;
    color: transparent;
    transition: .2s;
    display: flex;
    justify-content: center;
    align-items: center;

    img {
        visibility: hidden;
        opacity: 0;
        transition: .2s;
        width: 1.1rem;
        height: 1.1rem;
    }

}

//   input[type=checkbox] + label:active .check {
//     transform: scale(0);
//   }

input[type=checkbox]:checked+label .check {
    background-color: $color-first;
    border-color: $color-first;
    color: #fff;

    img {
        visibility: visible;
        opacity: 1;
    }

}

input[type=checkbox]:disabled+label .check {
    transform: scale(1);
    //  border-color: #aaa;
}

input[type=checkbox]:checked:disabled+label .check {
    transform: scale(1);
    background-color: rgba($color: $color-first, $alpha: .3);
    border-color: rgba($color: $color-first, $alpha: .3)
}

.checkbox-span {
    margin-left: .2rem
}

.english {
    .form__radio-group {
        &:not(:last-child) {
            margin-left: 0;
            margin-right: 1rem;

            @include respond(m) {
                margin-right: 2rem;
                margin-left: 0;

            }
        }
    }

    .form {
        &__radio-label {
            padding-right: 0;
            padding-left: 3rem;
        }

        &__radio-button {
            right: auto;
            left: 0;
        }
    }

    .form__icon--left {
        left: auto;
        right: 1.4rem;

    }

    .form__icon {
        right: auto;
        left: 1.4rem;

    }

    .form__input.has-icon {
        padding-right: 1.6rem;
        padding-left: 4rem;
    }

    input[type=checkbox]+label .check {
        margin-right: .6rem;
        margin-left: 0;
    }

    .checkbox-span {
        margin-right: .2rem;
        margin-left: 0;
    }
}