// simple grid styles using float system

.row {
    max-width: 50rem;
    padding: 0 1.5rem;

    @include respond(m) {
        padding: 0 2rem;

        &--middle-floats {
            display: flex;
            align-items: center;
        }
    }


    &--no-padding {
        padding: 0;

        &_desktop {
            @include respond(m) {
                padding: 0;

            }
        }
    }



    @include respond(xs) {
        max-width: 75rem;
    }

    @include respond(s) {

        max-width: 105rem;
    }

    @include respond(m) {
        max-width: $grid-width;
    }

    @include respond(xl) {
        max-width: 131rem;
    }

    //if there is a space then ok, 
    //but if there is not, make the width 100% 
    // background-color: rgba(119, 119, 119, 0.342);
    margin: 0 auto;

    @include respond(m) {

        &--equal-height-at-large {
            display: flex;

            &>div {
                float: none;
                display: flex;
            }
        }
    }

    @include respond(m) {

        &--table-at-large {
            display: table;

            &>div {
                float: none;
                display: table-cell;
                width: 50%;
                vertical-align: middle;
            }
        }
    }


    &.gutter-large {
        &:not(:last-child) {
            margin-bottom: 4rem;

            @include respond(m) {
                margin-bottom: $gutter-vertical;
            }
        }

    }

    &.gutter-small {
        &:not(:last-child) {
            margin-bottom: 2.5rem;

            @include respond(m) {
                margin-bottom: 4rem;
            }
        }
    }

    @include clearfix;

    [class^="col-"] {
        float: right;
        padding: 1rem;

        &.col-padding-small {
            padding: 0;
        }

        &.col--no-padding-top {
            padding-top: 0;
            padding-bottom: 0;
        }

        &:not(:last-child) {
            margin-left: 0;
            margin-bottom: $gutter-vertical;

            @include respond(m) {
                margin-left: $gutter-horizontal;
                margin-bottom: 0;
            }
        }

        &.big-btm {


            margin-bottom: 1.8rem;

            @include respond(m) {
                margin-bottom: 0
            }


        }

        &.md-btm {


            margin-bottom: 1.6rem;

            @include respond(m) {
                margin-bottom: 0
            }


        }


        &.col-margin-small {
            margin-left: 0;

            @include respond(m) {
                margin-left: 1.5rem;
            }
        }

        &.col-no-margin {

            margin-left: 0 !important;
        }

        width: 100%;

        @include respond(xs) {
            &.w-5 {
                width: 50%;
            }
        }

        &.w-7 {
            width: 80%;
        }

        &.w-3 {
            width: 20%;
        }


    }


    @include respond(m) {

        .col-1-of-2,
        .col-1-of-2.w-5,
        .col-1-of-2.w-7 {
            width: calc((100% - #{$gutter-horizontal})/2);
        }

        .col-1-of-3,
        .col-1-of-3.w-5,
        .col-1-of-3.w-7,
        .col-1-of-3.w-3 {
            width: calc((100% - 2*#{$gutter-horizontal})/3);
        }

        .col-2-of-3 {
            width: calc(2*((100% - 2*#{$gutter-horizontal})/3) + #{$gutter-horizontal});
        }

        .col-1-of-4,
        .col-1-of-4.w-5,
        .col-1-of-4.w-7,
        .col-1-of-4.w-3 {
            width: calc((100% - 3*#{$gutter-horizontal})/4);
        }

        .col-2-of-4 {
            width: calc(2* ((100% - 3*#{$gutter-horizontal})/4) + #{$gutter-horizontal});
        }

        .col-3-of-4,
        .col-3-of-4.w-5,
        .col-3-of-4.w-7,
        .col-3-of-4.w-3 {
            width: calc(3* ((100% - 3*#{$gutter-horizontal})/4) + (2* #{$gutter-horizontal}));
        }
    }

}