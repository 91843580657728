.winners{
    position: relative;
    margin-top: -11.1rem;
    z-index: 3;
  
    @include respond(m){
     
    margin-top: 0;
    padding: 3.5rem 0;

    
    }
    &__wrapper{

    }
    &__header{
    //     position: absolute;
       
    //     left:0;
    //     right: 0;
    //     top: -10rem;
 
    // z-index: 2;
        &--content{
            background: $white-color;
            border-radius: $border-radius;
            box-shadow: $box-shadow-select;
            padding: 1.6rem;
            text-align: center;
            @include respond(m){
                background: none;
                border-radius: 0;
                box-shadow: none;
                padding: 0;
                text-align: right;
            }
        }
        @include respond(m){
           // position: relative;
            //top: 0;
        }
        }

    &__filter{
        display: flex;
        align-items: flex-end;
        margin: .2rem 0 3.2rem 0;
        justify-content: space-between;
        flex-wrap: wrap;
        h6{
            margin: 1.2rem 0;
    font-size: 1.6rem;
    font-family: 'SF-Medium';
    font-weight: 200;
    color:$color-typo-second;
        }
        >*:not(:last-child) {
            
            @include respond(m){
            margin-left: 2rem;

            }
        }
        >*{
            flex-basis: 100%;
            @include respond(m){
                flex: 1;

                }
        }
        .filter-btns{
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex: 1;
            >*:not(:last-child) {
                @include respond(xs){
                    margin-left: 1rem;

                    }
            }
            width: 100%;
            margin: 2rem 0 0;
            >*{
                flex-basis: 49%;
                @include respond(xs){
                    flex-basis: auto;

    
                    }
            }
            @include respond(xs){
                margin: 2rem auto 0;
            width: auto;
               

                }
            @include respond(m){
            margin: 0;

            }
        }
        .filter-select{
            min-width: 250px;
        }
    }
}

.english{
    .winners__filter .filter-btns > *:not(:last-child){
        margin-left: 0;
        margin-right: 1rem;
    }
    .winners__filter {
        >*:not(:last-child) {
       
            @include respond(s){
            margin-right: 2rem;
            margin-left: 0;
    
            }
        }
    }
    .winners__header--content{
        @include respond(m){
            text-align: left;
        }
    }
}