.modal-content {

    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-bottom: 1.4rem;
        padding-left: 1.8rem;
        padding-right: 1.8rem;

        h3 {
            font-family: 'SF-Medium';
            font-size: 1.8rem;
            line-height: 22px;
        }

        a {
            cursor: pointer;

            img {
                width: 1.4rem;
                height: 1.8rem;
                vertical-align: middle;
            }
        }

    }

    &-line {
        height: 1px;
        margin-left: 1.8rem;
        margin-right: 1.8rem;
        background: $color-input;
    }

    &_body {
        overflow: auto;
        width: 100%;
        padding-bottom: 1rem;
        //   max-height: 77%;

    }
}