.my-data {


    &__box {
        background: $white-color;
        box-shadow: $box-shadow-select;
        border-radius: $border-radius;
        padding: 2.4rem 2.8rem 2.8rem;

        @include respond(m) {
            padding: 2.4rem 2.8rem 2.6rem;

        }
    }

    position: relative;
    margin-top: -11rem;
    z-index: 2;

    @include respond(m) {
        margin-top: 0;
        padding-top: 2.4rem;

    }
}

.password-resign {
    position: relative;
    margin-top: -17rem;
    z-index: 2;

    @include respond(m) {
        width: 35%;
        min-width: 600px;
        margin: 0 auto;
        top: -19rem;

    }

    &__box {
        background: $white-color;
        box-shadow: $box-shadow-select;
        border-radius: $border-radius;
        padding: 2rem 1.2rem 2rem;

    }

    &-title {
        color: $white-color;
        font-family: "SF-Medium";
        font-weight: 100;
        font-size: 2rem;
        margin-bottom: 1.4rem;
        text-align: center;
        line-height: 24px;

        @include respond(m) {
            line-height: 29px;
            font-size: 2.4rem;

        }
    }

    &-sub {
        text-align: center;
        font-family: "SF-Regular";
        line-height: 24px;
        font-weight: 100;

        color: $white-color;

        font-weight: 100;
        font-size: 1.7rem;
        margin-bottom: 2.4rem;

        @include respond(m) {

            font-size: 1.8rem;



        }
    }
}

.participations {
    padding-top: 3.5rem;
    position: relative;
    margin-top: -145px;
    z-index: 2;

    @include respond(xs) {
        margin-top: -120px;


    }

    @include respond(s) {
        top: 0;
        margin-top: 0;
        padding-top: 2.6rem;


    }

    &__wrapper {
        margin-top: .3rem;
    }
}

.notifies {
    padding-top: 3.5rem;

    position: relative;
    margin-top: -146px;
    z-index: 2;

    @include respond(m) {
        top: 0;
        margin-top: 0;

        padding-top: 2.6rem;

    }
}

.notification {
    cursor: pointer;
    padding: .8rem .8rem .8rem 1.4rem;
    background: $white-color;
    border-radius: $border-radius;
    box-shadow: $box-shadow-select;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.2rem;

    // min-height: 8.5rem;
    &__img {
        margin-left: 1rem;

        &--box {

            width: 4.3rem;
            height: 100%;
            min-height: 4.3rem;

            @include respond(m) {
                width: 6.8rem;
                height: 100%;
                min-height: 6.9rem;
            }

            background: $color-input;
            border-radius: 4px;
            //padding: 1.9rem 1.6rem 2rem 1.4rem;
            display: flex;
            align-items: center;
            justify-content: center;

            span {
                text-align: center;
                color: $color-typo-second;

                font-size: .8rem;

                line-height: 10px;
                display: block;

                @include respond(m) {
                    font-size: 1.4rem;
                    line-height: 16px;
                }
            }
        }

        .date {
            font-size: .8rem;
            color: $color-typo-third;
            display: block;
            line-height: 10px;
            text-align: center;
            margin-top: .4rem;

            @include respond(m) {
                display: none;
            }
        }
    }

    &__text {
        flex: 1;

        h1 {
            font-size: 1.4rem;
            font-family: 'SF-Medium';

            line-height: 17px;
            color: $color-typo-main;

            @include respond(m) {
                line-height: 22px;
                font-size: 1.8rem;

            }
        }

        p {
            font-size: 1.2rem;
            color: $color-typo-second;
            margin: .6rem 0 0;
            line-height: 20px;

            @include respond(m) {
                font-size: $default-font-size;
                margin: .6rem 0 1rem;
                line-height: 21px;
            }

        }

        span {
            display: none;

            @include respond(m) {
                font-size: 1rem;
                color: $color-typo-third;
                display: block;
                line-height: 13px;
            }
        }
    }

    &__arrow {

        margin-right: 1rem;

        img {
            width: 1.2rem;
            height: 1.9rem;
        }

    }

}


.comp-details {
    &__body {
        padding: 1.6rem 0 1.8rem;

        &--data {
            display: flex;
            align-items: flex-start;
            justify-content: flex-start;
            flex-wrap: wrap;
            padding: 0 1.8rem;


        }

        &--img {
            width: 7.4rem;
            height: 7.4rem;
            margin-bottom: 1.6rem;

            border-radius: $border-radius;
            margin-left: 1.6rem;
            display: flex;
            align-items: center;
            justify-content: center;

            @include respond(s) {
                margin-bottom: 0;
                width: 16.2rem;
                height: 16.2rem;

            }

            background: $color-input;

            img {
                width: 20%;

            }
        }

        &--text {
            width: 100%;

            @include respond(s) {
                width: auto;

            }

            h1 {
                font-size: 2.2rem;
                font-family: 'SF-Medium';
                margin-bottom: 1.6rem;

            }

            p {
                line-height: 21px;
                margin-bottom: 1rem;
                color: $color-typo-second;

                strong {
                    color: $color-typo-main;

                    margin-right: .6rem;

                    &.font-big {
                        font-size: 2rem;
                        margin-right: 0;
                    }
                }

                .red {
                    color: $color-error;

                }

                .main {
                    color: $color-first;

                }
            }

            a {
                margin-top: 1.4rem;
                width: 100%;

                @include respond(s) {
                    width: 60%;

                }

                display: inline-block;
            }

            padding-bottom: .6rem;
        }

        &--btn {
            padding: 0 1.8rem;

        }
    }

    &__error {
        background: $color-error;
        padding: 1rem 1.8rem;
        color: $white-color;
        text-align: center;
    }

    &__footer {
        font-size: 1.4rem;
        color: $color-typo-second;
        padding-left: 1.8rem;
        padding-right: 1.8rem;

        p {
            font-size: 1.4rem;
            text-align: center;

        }

        &--btns {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                margin-right: 1rem;
            }
        }
    }

    &.big {
        padding: 1rem 1rem 1.6rem;

        @include respond(m) {
            padding: 2rem;

        }

        background: $white-color;
        border-radius: $border-radius;
        box-shadow: $box-shadow-select;

        .comp-details {
            &__body {
                padding: 0;

                &--img {
                    width: 100%;
                    height: 30.2rem;
                    margin-bottom: 2.5rem;
                    margin-left: 0;

                    // @include respond(s){

                    //     width: 30.2rem;


                    //     }
                    @include respond(m) {
                        margin-bottom: 0;
                        width: 50rem;
                        height: 50rem;
                        margin-left: 2rem;

                    }
                }

                input {
                    width: auto;
                }

                &--data {
                    padding: 0;
                }

                &--text {
                    padding: 0;

                    width: 100%;
                    flex: 100%;

                    @include respond(m) {
                        width: auto;
                        flex: 1;

                    }

                    .long {
                        margin-bottom: 1.6rem;

                        strong {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }
}

.new-account-form {
    padding: 1.6rem 2rem 0;

    &.message {
        padding-top: 2rem;
        padding-bottom: .2rem;
    }
}

.message-box {
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    img {
        height: 4.7rem;
        width: 4.7rem;
        margin-bottom: 2rem;
    }

    p {
        display: inline-block;
        margin-bottom: 2rem;

    }
}

.upload {
    h6 {
        margin-bottom: .6rem;
        font-size: 1.4rem;
    }

    &-info {
        width: 45%;
        overflow: hidden;

        @include respond(xxxs) {
            width: 50%;

        }

        @include respond(xxs) {
            width: 60%;

        }

        @include respond(xs) {
            width: 70%;

        }

    }

    .upload-close {
        left: 1.7rem
    }

    .upload-img {
        padding-left: .9rem;
    }
}

.policy {
    &__content {
        background: $color-input;
        border-radius: .4rem;
        padding: .8rem .8rem 1.2rem 1rem;
        margin-bottom: 1.2rem;

        p {
            font-size: 1.4rem;
            line-height: 19px;
        }
    }

    &__btns {
        display: flex;
        align-items: center;

        .first {
            margin-left: 1rem;

        }
    }
}




.goBack-btn {

    display: flex;
    align-items: center;
    justify-content: center;

    img {
        width: 1.4rem;
        margin-left: .6rem;
        vertical-align: middle;
    }

    &.right {
        justify-content: flex-start;
    }

    &.top {
        margin-bottom: 2rem;
    }

    &.bottom {
        margin-top: 2.6rem;
    }
}

.rules {

    position: relative;
    margin-top: -11.1rem;
    padding: 1.3rem 1.2rem 2.2rem;
    background: $white-color;
    box-shadow: $box-shadow-select;
    border: 1px solid$color-input;
    border-radius: $border-radius-md;
    z-index: 2;

    @include respond(m) {
        margin-top: 0;


        padding: 0 0 0;
        top: 0;
        background: transparent;
        box-shadow: none;
        border: none;
    }

    h1 {
        font-size: 1.8rem;
        font-family: 'SF-Medium';
        line-height: 24px;
        text-align: center;
        margin-bottom: 2.3rem;

        @include respond(m) {
            font-size: 2.6rem;
            line-height: 31px;
            text-align: right;

        }

        strong {
            margin-top: 1.2rem;
            display: inline-block;

            @include respond(m) {
                margin-top: 0;

            }
        }
    }

    p {
        line-height: 30px;
        color: $color-typo-second;

        @include respond(m) {
            font-size: 2rem;

        }
    }
}

.race {
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;

    margin: 0rem auto;

    &__box {
        max-width: 39.8rem;
        text-align: center;
    }

    h1 {
        font-size: 2.4rem;
        font-family: 'SF-Medium';
        margin-bottom: 2rem;
        text-align: center;
    }

    img {
        width: 100%;
        max-width: 39.8rem;
        height: auto;
        margin-bottom: 2rem;
    }

    a {
        width: 100%;
        max-width: 39.8rem;
    }

    button {
        width: 100%;
        max-width: 39.8rem;
    }

    &.caro {
        display: block;
        margin: 0;
    }

    &__slide-box {
        text-align: center;

        img {
            margin-bottom: 0;
        }

        .slider {
            //margin-bottom: 2rem ;
            position: relative;

            @include respond(m) {
                &::before {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    position: absolute;
                    right: 0;
                    height: 100%;
                    width: 35%;
                    z-index: 5;
                    background: rgba(247, 247, 247, .4);
                    background: linear-gradient(90deg, rgba(247, 247, 247, 0) 0%, rgba(247, 247, 247, 1) 100%);

                }

                &::after {
                    content: '';
                    position: absolute;
                    display: inline-block;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 35%;
                    z-index: 5;
                    background: rgba(247, 247, 247, .4);
                    background: linear-gradient(-90deg, rgba(247, 247, 247, 0) 0%, rgba(247, 247, 247, 1) 100%);

                }
            }
        }
    }
}



.english {
    .rules {
        @include respond(m) {
            padding-top: 2rem;
        }

        h1 {

            @include respond(m) {
                text-align: left;

            }
        }
    }

    .goBack-btn img {
        margin-left: 0;
        margin-right: .6rem;
        transform: rotate(180deg);
    }

    .notification__img {
        margin-left: 0;
        margin-right: 1rem;
    }

    .notification__arrow {
        // margin-right: 0;
        // margin-left: 1rem;
        transform: rotate(180deg)
    }

    .comp-details__body--img {
        margin-right: 1.6rem;
        margin-left: 0;
    }

    .comp-details.big .comp-details__body--img {
        @include respond(m) {
            margin-right: 2rem;
            margin-left: 0;
        }
    }

    .upload {
        .upload-close {
            left: auto;
            right: 1.7rem
        }

        .upload-img {
            padding-right: 9px;
            padding-left: 0;
        }
    }

    .upload-container {
        padding: 1rem 1.7rem 0.9rem !important;
    }

    .policy__btns .first {
        margin-right: 1rem;
        margin-left: 0;
    }
}