.btn {
    transition: all .3s;

    &,

    &:visited {
        text-decoration: none;
        padding: 1rem 1.6rem 1.1rem;
        font-size: $default-font-size;
        display: inline-block;
        border-radius: $border-radius;
        transition: all .2s ease-in-out;
        position: relative;
        // vertical-align: top;
        text-align: center;
        border: 1px solid transparent;
        -webkit-backface-visibility: hidden;
        font-family: 'SF-Regular';
        cursor: pointer;
        backface-visibility: hidden;
        //  box-shadow: 0 0.5rem .7rem rgba(0, 0, 0, .2);

        // outline: none;
        //transform: translateZ(0) scale(1.0, 1.0);


        @include respond(xs) {
            font-size: 1.7rem;
        }

        &:hover {
            box-shadow: none;

            // height: 5.1rem;
            // -webkit-transform: translate(0, -3px) rotate(0.03deg);
            // -moz-transform: translate(0, -3px) rotate(0.03deg) ;
            transform: translate(0, -3px);
            // &::after {
            //     /*we style the after element that sit behind the btn elemnt*/
            //     transform: scaleX(1.5) scaleY(1.3);
            //     opacity: 0;
            // }
        }


        &:active,
        &:focus {
            outline: none;
            ///   transform: translateY(-1px);

        }
    }

    &.bold {
        &,

        &:visited {
            font-family: 'SF-Medium';
        }
    }

    &.middle {
        &,

        &:visited {
            padding: 1.2rem 1.6rem 1.6rem;

            height: 5rem;
        }

        &.pd-0 {
            &,

            &:visited {
                padding: 0;

            }
        }

        &.has-icon {
            padding: 1.2rem .7rem 1.6rem;
            font-size: 1.4rem;

            img {
                height: 1.2rem;
                margin-left: .7rem;
            }

            @include respond(xxxs) {
                padding: 1.5rem 1.6rem 1.6rem;

                img {
                    margin-left: 1.2rem;
                    height: 1.4rem;

                }
            }
        }
    }

    &.big-padding {
        padding-right: 6rem;
        padding-left: 6rem;
    }

    &.custom-padding {
        &,

        &:visited {
            padding: 1.4rem 2rem 1.8rem;
            height: 5.4rem;
        }
    }

    &.small-padding {
        &,

        &:visited {
            padding: .7rem 1.6rem;

        }
    }



    &--dropdown {

        &,
        &:visited {
            padding: .3rem .3rem .3rem 1rem;
            display: flex;
            align-items: center;
            justify-content: center;
            font-family: 'SF-Regular';
            font-size: 1.4rem;
            border-radius: 20px;
            line-height: 19px;
            background: $white-color;
        }

        span {
            color: $color-typo-second;

        }

        .icon {
            height: 3.4rem;
            width: 3.4rem;
            background: $color-input;
            margin-left: .6rem;
            border-radius: 50%;

            display: flex;
            align-items: center;
            justify-content: center;

        }

        img {
            width: 1.2rem;
            height: 1.4rem;
            vertical-align: bottom;

        }

        .arrow {
            margin-right: 1.2rem;
        }

        &-blue {

            &,
            &:visited {
                background: $color-first;

                span {
                    color: $white-color;

                }

                .icon {
                    background: $white-color;
                }
            }
        }
    }

    &--circle {

        &,
        &:visited {
            height: 4rem;
            width: 4rem;

            border: 1px solid $white-color;

            border-radius: 50%;
            position: relative;
        }


        img {
            width: 1.8rem;
            height: 2.1rem;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }

        .onHover {
            visibility: hidden;
            opacity: 0;
        }

        span {
            border: none;

            height: 1.9rem;
            border-radius: 50%;

            width: 1.9rem;
            border: 1px solid $white-color;
            background: $color-second;
            color: $white-color;
            font-family: 'SF-Medium';
            font-size: 1rem;
            line-height: 12px;
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -.5rem;
            right: -.9rem;
        }

        &:hover {
            background-color: $white-color;
            border: 1px solid $white-color;
            color: $white-color !important;

            .onHover {
                visibility: visible;
                opacity: 1;
            }

            .normal {
                visibility: hidden;
                opacity: 0;
            }
        }

        &-blue {

            &,
            &:visited {
                background: $color-first;
                border: 1px solid transparent;
            }

            &:hover {
                background-color: transparent;
                border: 1px solid $color-first;

                .onHover {
                    visibility: visible;
                    opacity: 1;
                }

                .normal {
                    visibility: hidden;
                    opacity: 0;
                }
            }
        }
    }

    &--normal {

        &,
        &:visited {
            padding: 0;
            font-size: 1.4rem;
            color: $color-typo-second;
            border-radius: 0;
            transition: all .3s;
            position: relative;
        }

        &:hover {
            color: $color-typo-main;
            transform: none;
        }

        &-blue {

            &,
            &:visited {
                color: $color-first;
            }

            &:hover {
                color: $color-typo-main;

            }
        }

        &-white {

            &,
            &:visited {
                color: $white-color;
            }
        }

    }

    &--yellow {

        &,
        &:visited {
            border-radius: $border-radius-sm;
            background-color: $color-first;
            color: $white-color !important;
            // box-shadow: 0 0.5rem .7rem rgba($color-first, .2);
            // box-shadow: $box-shadow-select;
        }

        &:hover {
            // background-color: transparent;
            // border: 1px solid $color-first;
            // color: $color-first !important;
            background-color: rgba($color: $color-first, $alpha: .7);


        }

        // &::after {
        //     background-color: $color-first;

        // }
        &.has-shadow {
            box-shadow: 0 4px 12px rgba($color-first, .4);
            border-radius: $border-radius;

            &:hover {
                box-shadow: none;
            }

        }

        // &:hover  {
        //     box-shadow: 0 1rem 1.5rem rgba($color-first, .2);

        // }

        // &:active,
        // &:focus {
        //     outline: none;

        //     box-shadow: 0 0.5rem .4rem rgba($color-first, .2);
        // }
    }

    &--reject {
        border-radius: $border-radius-sm;
        background-color: $color-first;
        color: $white-color !important;



    }

    &--green {

        &,
        &:visited {
            background-color: $color-second;
            color: $white-color !important;
            // box-shadow: 0 0.5rem .7rem rgba($color-second, .2);
            box-shadow: 0 4px 12px rgba($color-second, .4);

        }

        &:hover {
            // background-color: transparent;
            // border: 1px solid $color-second;
            // color: $color-second !important;
            background-color: rgba($color: $color-second, $alpha: .7);

        }

        &::after {
            background-color: $color-second;

        }

        // &:hover  {
        //     box-shadow: 0 1rem 1.5rem rgba($color-second, .2);

        // }

        // &:active,
        // &:focus {
        //     outline: none;

        //     box-shadow: 0 0.5rem .4rem rgba($color-second, .2);
        // }
    }

    &--red {

        &,
        &:visited {
            background-color: $color-error;
            color: $white-color !important;
            // box-shadow: 0 0.5rem .7rem rgba($color-error, .2);
            box-shadow: 0 4px 12px rgba($color-error, .2);
        }

        // &:hover {
        //     background-color: lighten($color: $color-error, $amount: 10%);
        // }
        &:hover {
            // background-color: transparent;
            // border: 1px solid $color-error;
            // color: $color-error !important;
            background-color: rgba($color: $color-error, $alpha: .7);

        }

        &.fixed-width {
            @include respond(xxxs) {
                min-width: 13.6rem;
            }

        }

        &.no-hover {
            &:hover {
                background-color: $color-error;
                color: $white-color !important;
                border: 1px solid transparent;
                background-color: rgba($color: $color-error, $alpha: .8);


            }
        }

        &::after {
            background-color: $color-error;

        }

        // &:hover  {
        //     box-shadow: 0 1rem 1.5rem rgba($color-error, .2);

        // }

        // &:active,
        // &:focus {
        //     outline: none;

        //     box-shadow: 0 0.5rem .4rem rgba($color-second, .2);
        // }
    }

    &--disabled {

        background-color: $color-disabled;
        color: $white-color !important;
        // box-shadow: 0 0.5rem .7rem rgba($color-disabled, .2);
        box-shadow: 0 4px 12px rgba($color-disabled, .4);

        cursor: not-allowed;

        &:hover {
            transform: none;
        }

        &.reject {
            color: $color-typo-second !important;
            box-shadow: none;
            border-radius: $border-radius-sm;
            cursor: pointer;

            &:hover {
                transform: translateY(-2px);
            }
        }

        // &:active,
        // &:focus {
        //     outline: none;

        //     box-shadow: 0 0.5rem .4rem rgba($color-second, .2);
        // }
    }

    &.has-md-radius {
        border-radius: $border-radius - 4px;
    }

    &--white {

        &,
        &:visited {
            background-color: $white-color;
            color: $color-first !important;
            border-radius: $border-radius-sm;
        }

        // box-shadow: 0 0.5rem .7rem rgba($white-color, .2);
        &:hover {
            // background-color: transparent;
            // border: 1px solid $white-color;
            // color: $white-color !important;

        }

        &.img-only {
            height: 4.5rem;


            img {
                height: 100%;
            }
        }

        &.no-hover {
            &:hover {
                background-color: $white-color;
                color: $color-first !important;
                border: 1px solid transparent;


            }
        }

        &::after {
            background-color: $white-color;
        }

        &.has-long-radius {
            border-radius: $border-radius;

        }

        &.changed {
            background-color: $color-first;
            color: $white-color !important;
        }

        // &:hover {
        //     background-color: lighten($color: $white-color, $amount: 10%);
        // }

        // &:hover  {
        //     box-shadow: 0 1rem 1.5rem rgba($white-color, .2);

        // }

        // &:active,
        // &:focus {
        //     outline: none;

        //     box-shadow: 0 0.5rem .4rem rgba($color-first, .2);
        // }
    }

    &--white-border {

        &,
        &:visited {
            background-color: transparent;
            color: $white-color;
            // box-shadow: 0 0.5rem .7rem rgba($white-color, .2);
            // box-shadow: $box-shadow-select;

            border: 1px solid $white-color !important;
        }

        &:hover {
            background-color: $white-color;
            border: 1px solid $white-color;
            color: $color-first !important;

        }


        &.changed {
            background-color: transparent;
            color: $color-first;
            border: 1px solid $color-first !important;

        }

        // &:hover {
        //     background-color: lighten($color: $white-color, $amount: 10%);
        // }
        // &::after {
        //     background-color: transparent;
        // border: 1px solid $white-color !important;

        // }


        // &:active,
        // &:focus {
        //     outline: none;

        //     box-shadow: 0 0.5rem .4rem rgba($color-first, .2);
        // }
    }

    &--main-border {

        &,
        &:visited {
            background-color: transparent;
            color: $color-first !important;
            // box-shadow: 0 0.5rem .7rem rgba($color-first, .2);
            // box-shadow: $box-shadow-select;
            border: 1px solid $color-first !important;
        }

        &:hover {
            background-color: $color-first;
            border: 1px solid $color-first;
            color: $white-color !important;

        }

        // &:hover {
        //     background-color: lighten($color: $color-first, $amount: 10%);
        // }
        // &::after {
        //     background-color: transparent;
        //     border: 1px solid $color-first !important;

        // }
        &:hover {
            // box-shadow: 0 1rem 1.5rem rgba($color-first, .2);

        }

        &:active,
        &:focus {
            outline: none;

            // box-shadow: 0 0.5rem .4rem rgba($color-first, .2);
        }
    }

    &.has-icon {
        display: flex;
        align-items: center;
        justify-content: center;

        img {
            width: 1rem;
            height: 1.4rem;
            margin-left: 1.2rem;
        }
    }

    &__back-to-top {

        &,

        &:visited {
            padding: 1rem 2rem;
            position: fixed;
            bottom: 4rem;
            left: 4rem;
        }

        &.btn__back-to-top--hidden {

            @include respond(m) {
                display: none;
            }
        }

    }

    // &::after {
    //     content: "";
    //     display: inline-block;
    //     height: 100%;
    //     width: 100%;
    //     border-radius: $border-radius;
    //     position: absolute;
    //     top: 0;
    //     left: 0;
    //     z-index: -1;
    //     transition: all .4s;
    // }

    &--animated {
        animation: moveInBottom .5s ease-in-out .75s;
        animation-fill-mode: backwards;
        /*the styles in 0% are applyied before it starts (due to .75s delay) */
    }



    &__back-to-main {

        &,

        &:visited {
            padding-left: 2rem;
            position: fixed;
            bottom: 4rem;
            left: 4rem;

            color: $white-color;
        }

        &::after {

            content: "الرئيسية";
            width: 15rem;
            height: 5.5rem;
            display: inline-block;
            position: absolute;
            padding: 1rem;
            padding-right: 3rem;
            top: 0;
            left: 0;

            transition: all .3s;
            backface-visibility: hidden;
            background-color: $color-typo-main;

        }
    }

}

.btn-text {

    &,

    &:visited {
        font-size: $default-font-size;
        color: $color-first;
        //  display: inline-block;
        // font-weight: 700;
        text-decoration: none;
        //  border-bottom: 1px solid $color-first;
        padding: 1px;
        transition: all .3s;
        box-shadow: none;

    }


    &:hover {
        // background-color: $color-first;
        color: $white-color;
        // box-shadow: 0 1rem 2rem rgba($color: $color-typo-main, $alpha: .4);
        transform: none;
        box-shadow: none;
        text-decoration: underline;
    }

    &:active,
    &:focus {

        transform: none;

    }

    &--black {

        &,

        &:visited {


            color: $color-typo-second;
            //  padding: .5rem;
            text-decoration: underline;

        }

        &:hover {

            color: $color-typo-main;
            text-decoration: none;

        }


    }

    &--white {

        &,

        &:visited {


            color: $white-color;


        }




    }

    &--dark {

        &,

        &:visited {


            color: $color-typo-main;

            text-decoration: underline;

        }

        &:hover {

            color: $color-typo-second;
            text-decoration: none;

        }


    }

    &.middle {
        @include respond(m) {
            font-size: 2rem;
        }
    }

    &.hovered {
        color: $color-first !important;

        &:hover {

            color: $color-typo-main !important;

        }
    }

    &.disabled {
        color: $color-typo-third !important;
        cursor: not-allowed;

        &:hover {
            text-decoration: none;
            color: $color-typo-third !important;

        }
    }
}

.btn-link {

    &,

    &:visited {
        font-size: 1.4rem;
        color: $color-typo-second;

        box-shadow: none;
    }

    &:hover {


        text-decoration: underline;
        transform: none;
    }

    &:active,
    &:focus {

        transform: none;

    }

    &--white {

        &,

        &:visited {
            color: $white-color;
        }

    }
}

.btn {
    &--small {

        &,

        &:visited {
            font-size: 1.4rem !important;

        }
    }

    &.has-md-radius {
        &,

        &:visited {
            border-radius: $border-radius-md;

        }
    }
}


.english {
    .btn.middle.has-icon {
        img {
            margin-left: 0;

            margin-right: .7rem;
        }

        @include respond(xxxs) {


            img {
                margin-right: 1.2rem;
                margin-left: 0;

            }
        }
    }

    .btn--circle span {
        right: auto;
        left: -.9rem
    }

    .btn--dropdown .icon {
        margin-left: 0;
        margin-right: .6rem;
    }

    .btn--dropdown,
    .btn--dropdown:visited {
        padding: .3rem 1rem .3rem .3rem;
    }

    .btn--dropdown .arrow {
        margin-left: 1.2rem;
        margin-right: 0;

    }
}