.auth {
    display: flex;

    background-size: 0% 0% !important;
    background-position: center top !important;
    position: relative;

    @include respond(m) {
        padding: 1rem;
        background-size: cover !important;
        background-position: center bottom !important;
        align-items: center;
        justify-content: space-between;


    }

    &__mobile-bg {
        position: absolute;
        background: $color-first;
        left: 0%;
        right: 0;
        z-index: -1;

        @include respond(m) {
            display: none;
        }
    }

    &__right {
        flex: 1;

        @include respond(m) {
            background: #FCFCFC;
            height: 100%;
            border-radius: $border-radius;
        }

    }

    &__content {
        // width: 85%;
        min-height: 98vh;

        // margin: 0 auto;
        padding: 1rem;

        position: relative;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        flex-direction: column;
        height: 100%;

        @include respond(xxs) {
            padding: 3rem 1.8rem 1.8rem 2rem;

        }

        @include respond(m) {
            padding: 9.3% 15.5% 3.1%;
            min-height: 96vh;
            justify-content: space-between;


        }
    }

    &__nav {
        display: flex;
        justify-content: space-between;
        align-items: center;

        min-width: 100%;

        img {
            width: 11rem;
        }

        ul {
            //flex: 1;
            display: flex;
            justify-content: flex-end;
            align-items: center;

            .desktop-btn {
                display: none;

                @include respond(m) {
                    display: inline-block;

                }
            }

            .mobile-btn {
                @include respond(m) {
                    display: none;

                }
            }
        }

        .main-logo {
            display: none;

            @include respond(m) {
                display: block;

            }
        }

        .white-logo {

            @include respond(m) {
                display: none;

            }
        }
    }

    &__body {
        padding: 4rem 0 3rem 0;
        margin-bottom: 5rem;

        @include respond(m) {
            padding: 5rem 0 3rem 0;
            margin-bottom: 0;


        }

        width:100%;

        &--btn {
            margin-top: 2.6rem;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 1.4rem;
                margin-left: .6rem;
            }
        }
    }






    &__footer {
        font-size: 1.4rem;
        color: $color-typo-second;
        position: absolute;
        bottom: 1.3rem;

        @include respond(m) {
            padding: 5rem 0 3rem 0;
            position: relative;
            bottom: 0;
        }

        p {
            font-size: 1.4rem;

        }

        &--btns {
            display: flex;
            justify-content: center;
            align-items: center;

            p {
                margin-right: 1rem;
            }
        }
    }

    &__left {
        display: none;

        @include respond(m) {
            flex-basis: 40%;
            justify-content: center;
            display: flex;
            flex-direction: column;
            align-items: center;
        }

        @include respond(m) {
            flex-basis: 50%;

        }

        img {
            //width: 50px;

            height: auto;
            padding: 1.5rem;
            margin: 1.5rem auto;
        }

        .slick-slider {
            width: 300px;
            max-width: 50%;
        }
    }


    &__heading {
        font-size: 2.4rem;
        font-family: 'SF-Medium';
        line-height: 29px;
        text-align: center;
        color: $white-color;
        margin: 0rem 0 3rem;

        @include respond(m) {
            color: $color-first;

        }

        @include respond(l2) {
            display: none;


        }

        &.nav {
            margin: 0;
            display: none;
            position: relative;

            @include respond(l2) {
                display: inline-block;


            }
        }

        //     @include respond(xl){
        //         display: inline-block;
        // /* margin-right: auto; */
        // /* margin-left: auto; */
        // position: absolute;
        // top: 9.3%;
        // left: 53%;
        // transform: translateX(-50%);
        // margin: 0;
        //     }
    }

    &__title {
        font-size: 2.4rem;
        font-family: 'SF-Medium';
        line-height: 29px;
    }

    &__subtitle {
        font-size: 1.8rem;
        line-height: 20px;
        color: $color-typo-second;
        margin: 1.4rem 0 2.3rem;
        line-height: 24px;

        .btn {
            &,

            &:visited {
                font-size: 1.8rem;
            }
        }

        &.has-sub {
            margin: 2.4rem 0 .6rem;

        }

        &.is-sub {
            margin-top: 0;
            line-height: 22px;

        }

        span {
            color: $color-typo-main;

            @include respond(m) {
                color: $color-first;

            }
        }
    }

    &__box {
        padding: 2rem 1.2rem;
        background: $white-color;
        box-shadow: $box-shadow-select-bg;
        border-radius: $border-radius;
    }

    &__group {
        display: flex;

        .input {
            margin-left: 1rem;
            flex: 1;
        }

        .select {
            &__control {
                width: 6.8rem;
                height: 5rem;

                @include respond(m) {
                    width: 7.5rem;

                }
            }
        }
    }

}


.code-form {

    &.small {
        .auth__title {
            font-size: 1.8rem;

            text-align: center;
            line-height: 24px;

            @include respond(xs) {
                font-size: 2rem;
            }
        }

        .auth__subtitle {
            font-size: 1.6rem;
            text-align: center;
            line-height: 26px;

            @include respond(xs) {
                font-size: 1.8rem;

            }

            span {
                color: $color-first;
            }

        }

        .auth__box {
            background: none;
            padding: 0;
            box-shadow: none;
        }

        .form__footer {
            padding-top: .4rem;
            // .btn-text.hovered{
            //     color: $color-first  !important;
            // }
            // @include respond(m){
            //     .btn-text.hovered{
            //         color: $color-first  !important;
            //     }
            // }
            // .btn-text.hovered.disabled{
            //     color: $color-typo-third !important;


            // }
        }
    }
}



.english {
    .auth__group .input {
        margin-left: 0;
        margin-right: 1rem;
    }

    .auth__body--btn img {

        margin-right: .6rem;
        margin-left: 0rem;
        transform: rotate(180deg);
    }

    .auth__footer--btns p {
        margin: 0 .5rem;
    }

    .auth__nav {
        ul {
            flex-direction: row-reverse;
        }
    }
}